.reset-button {
    border: 3px solid #2dcfa7 !important;

    &:hover {
        box-shadow: 0.2rem .5rem 2.5rem rgba(255, 0, 0, 0.175) !important;
        border-color: red !important;
        color: red !important;
    }

    &:focus {
        outline: none !important;
    }

    &:focus-visible {
        outline: 2px solid red !important;
        outline-offset: 2px !important;
    }
}

.info-window {
    background-color: white;
    max-height: calc(100% - 1rem);
    overflow-y: auto;
    padding: 1rem 1.5rem;
    top: 0.5rem;
    left: 0.5rem;
    position: absolute;
    box-shadow: 2px 2px 3rem #0003;
    border: 1px solid #0003;

    &-same-locs {
        right: 0.5rem;
        left: unset;
    }

    span {
        font-weight: 700;
    }

    ul {
        list-style: none;
        padding: 1rem 0 0.5rem;
        margin-bottom: 0;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
    }
}