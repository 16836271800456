$col01: #3ac1f4;
$col02: #74d3a3;
$col03: #fac957;
$col04: #ff9b04;
$col05: #2dcfa7;
$col06: #30e990;
$col07: #30e990;
$col08: #e52d21;
$col09: #e5e5e5;
$col10: #979797;

$col11: #15b3ff;
$col12: #c496ff;
$col13: #cbd400;
$col14: #abb300;
$col15: #ffc005;
$col16: #25d4a2;
$col17: #d9baff;
$col18: #9812d8;
$col19: #fff068;
$col20: #df0000;
$col21: #157fb1;
$col22: #72d1ff;

$col-filt-01: #ff0000;
$col-filt-02: #f75409;
$col-filt-03: #a35a00;
$col-filt-04: #000;
$col-filt-05: #016c94;
$col-filt-06: #000;
$col-filt-07: #00a651;
$col-filt-08: #bd017e;
$col-filt-09: #ff0000;
$col-filt-10: #4b4b4b;
$col-filt-11: #bbbbbb;
$col-filt-12: #ffa500;
$col-filt-13: #00f;

$corner-radius: 0.2rem;

.errorMessage {
  color: red;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.filt {
  color: white;
  text-decoration: none;
  padding: 4px 8px;
  border-radius: 4px;
  display: inline-block;
  vertical-align: text-top;

  &:hover {
    color: rgb(87, 58, 5);
  }

  &-0 {
    border: 1px solid $col-filt-01;
    color: $col-filt-01;

    &:hover,
    &:focus,
    &:active {
      background: lighten($col-filt-01, 45%);
      color: #000;
    }
  }

  &-1 {
    background-color: $col-filt-01;

    &:hover,
    &:focus,
    &:active {
      background: lighten($col-filt-01, 45%);
      color: #000;
    }
  }

  &-2 {
    background-color: $col-filt-02;

    &:hover,
    &:focus,
    &:active {
      background: lighten($col-filt-02, 45%);
      color: #000;
    }
  }

  &-3 {
    background-color: $col-filt-03;

    &:hover,
    &:focus,
    &:active {
      background: lighten($col-filt-03, 45%);
      color: #000;
    }
  }

  &-4 {
    background-color: $col-filt-04;

    &:hover,
    &:focus,
    &:active {
      background: lighten($col-filt-04, 45%);
      color: #fff;
    }
  }

  &-5 {
    background-color: $col-filt-05;

    &:hover,
    &:focus,
    &:active {
      background: lighten($col-filt-05, 45%);
      color: #000;
    }
  }

  &-6 {
    background-color: $col-filt-06;

    &:hover,
    &:focus,
    &:active {
      background: lighten($col-filt-06, 45%);
      color: #fff;
    }
  }

  &-7 {
    background-color: $col-filt-07;

    &:hover,
    &:focus,
    &:active {
      background: lighten($col-filt-07, 45%);
      color: #000;
    }
  }

  &-8 {
    background-color: $col-filt-08;

    &:hover,
    &:focus,
    &:active {
      background: lighten($col-filt-08, 45%);
      color: #000;
    }
  }

  &-9 {
    background-color: $col-filt-09;

    &:hover,
    &:focus,
    &:active {
      background: lighten($col-filt-09, 45%);
      color: #000;
    }
  }

  &-10 {
    background-color: $col-filt-10;

    &:hover,
    &:focus,
    &:active {
      background: lighten($col-filt-10, 45%);
      color: #000;
    }
  }

  &-11 {
    background-color: $col-filt-11;

    &:hover,
    &:focus,
    &:active {
      background: lighten($col-filt-11, 45%);
      color: #000;
    }
  }

  &-12 {
    background-color: $col-filt-12;

    &:hover,
    &:focus,
    &:active {
      background: lighten($col-filt-12, 45%);
      color: #000;
    }
  }

  &-13 {
    background-color: $col-filt-13;

    &:hover,
    &:focus,
    &:active {
      background: lighten($col-filt-13, 45%);
      color: #000;
    }
  }
}

.tag {
  color: white;
  text-decoration: none;
  padding: 1px 6px;
  border-radius: 4px;
  display: inline-block;
  vertical-align: text-top;

  &:hover {
    color: rgb(87, 58, 5);
  }

  &-1 {
    background-color: $col-filt-01;

    &:hover,
    &:focus,
    &:active {
      background: lighten($col-filt-01, 45%);
    }
  }

  &-2 {
    background-color: $col-filt-02;

    &:hover,
    &:focus,
    &:active {
      background: lighten($col-filt-02, 45%);
    }
  }

  &-3 {
    background-color: $col-filt-03;

    &:hover,
    &:focus,
    &:active {
      background: lighten($col-filt-03, 45%);
    }
  }

  &-4 {
    background-color: $col-filt-04;

    &:hover,
    &:focus,
    &:active {
      background: lighten($col-filt-04, 45%);
    }
  }

  &-5 {
    background-color: $col-filt-05;

    &:hover,
    &:focus,
    &:active {
      background: lighten($col-filt-05, 45%);
    }
  }

  &-6 {
    background-color: $col-filt-06;

    &:hover,
    &:focus,
    &:active {
      background: lighten($col-filt-06, 45%);
    }
  }

  &-7 {
    background-color: $col-filt-07;

    &:hover,
    &:focus,
    &:active {
      background: lighten($col-filt-07, 45%);
    }
  }

  &-8 {
    background-color: $col-filt-08;

    &:hover,
    &:focus,
    &:active {
      background: lighten($col-filt-08, 45%);
    }
  }

  &-9 {
    background-color: $col-filt-09;

    &:hover,
    &:focus,
    &:active {
      background: lighten($col-filt-09, 45%);
    }
  }

  &-10 {
    background-color: $col-filt-10;

    &:hover,
    &:focus,
    &:active {
      background: lighten($col-filt-10, 45%);
    }
  }

  &-11 {
    background-color: $col-filt-11;

    &:hover,
    &:focus,
    &:active {
      background: lighten($col-filt-11, 45%);
    }
  }
}

.greenBorder {
  border: 3px solid $col05;
}

.btn-pagination {
  background-color: white;
  border: none;
  padding: 0.2rem 0.8rem;
  border: 2px solid $col05;

  &:hover {
    background-color: $col05;
    border: 2px solid white;
  }

  &_left {
    border-top-left-radius: $corner-radius;
    border-bottom-left-radius: $corner-radius;
    border-right: none;

    &:hover {
      border-right: none;
    }
  }

  &_right {
    border-top-right-radius: $corner-radius;
    border-bottom-right-radius: $corner-radius;
    border-left: 0px solid white;

    &:hover {
      border-left: none;
    }
  }
}

// hide api key missing notification for tinyMCE editor
.tox-notifications-container {
  display: none !important;
}

.form-label.required:after {
  content: '*';
  color: red;
}

.icon-toggle {
  cursor: pointer;
}

.marker-label {
  background-color: white;
  padding: 0.2rem 0.4rem;
  color: black;
  border: 1px solid black;
  border-radius: 0.2rem;
  font-size: 0.5rem;
  box-shadow: 0.2rem 0.2rem 0.5rem #0006;
  position: absolute;
  left: -0.3rem;
  top: 3.3rem;
}


.pac-container {
  z-index: 10000 !important;
}