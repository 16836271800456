// Override default variables before the import
// you can open the bootstrap.scss module and check any available variables.
$primary: #79cbea;
$secondary: #8f5325;
$success: #3e8d63;
$info: #7854e4;
$warning: #b8c924;
$danger: #d62518;
$light: #f8f9fa;
$dark: #30e990;

//project overides
// $link-color: $success;

// custom colors
$col01: #3ac1f4;
$col02: #74d3a3;
$col03: #fac957;
$col04: #ff9b04;
$col05: #2dcfa7;
$col06: #30e990;
$col07: #30e990;
$col08: #e52d21;
$col09: #e5e5e5;
$col10: #979797;

$col11: #15b3ff;
$col12: #c496ff;
$col13: #cbd400;
$col14: #abb300;
$col15: #ffc005;
$col16: #25d4a2;
$col17: #d9baff;
$col18: #9812d8;
$col19: #fff068;
$col20: #df0000;
$col21: #157fb1;
$col22: #72d1ff;

$form-border-color: $col06;

//custom color to be mapped
$accent: #da6d25;
// $primary-dark: darken($primary, 35%);
$primary-light: #79cbea;

// this is used to map colors. change accordingly.
$theme-colors: (
  primary: $primary,
  secondary: $secondary,
  success: $success,
  info: $info,
  warning: $warning,
  danger: $danger,
  light: $light,
  dark: $col06,
  // add any additional color below
  accent: $accent,
  primary-light: $primary-light,
  // now bg-accent,btn-accent,etc.. can be called
);

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';
